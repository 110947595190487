import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import s from './FocusLayout.scss';
import { FOOTERBAR_LINKS } from '../../constants';
import Brand from '../Brand';
import Clickable from '../Clickable/Clickable';
import Footer from '../Footer';
import FooterBar from '../FooterBar';
import Flag from '../Flag';
import Notifications from '../Notifications';

import withResponsiveWrapper from '../../decorators/withResponsiveWrapper';

import { loginMessages as messages } from '../../defineMessages';

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  });
};

const FocusLayout = ({
  intl: { formatMessage },
  children,
  lightTheme,
  paperTheme,
  noPadding,
  gradient,
  backgroundImage,
  footer,
  footerBar,
  logo,
  color,
  isMobile,
  countries,
  countryCode,
  selectCountryMessage,
  focusContentBox,
  centerContent,
}) => {
  let backgroundStyle = {};
  if (color && (isMobile || !backgroundImage)) {
    backgroundStyle = {
      backgroundColor: color,
    };
  } else if (backgroundImage && !isMobile) {
    backgroundStyle = {
      backgroundImage: `url(${backgroundImage})`,
    };
  }

  const country = countries?.find(c => c.value === countryCode);
  const currentCountry = country ? country.label : countryCode;

  return (
    <>
      <div
        id="main"
        className={cx(
          s.root,
          lightTheme && s.root__light,
          paperTheme && s.root__paper,
          noPadding && s.root__noPadding,
          backgroundImage && !isMobile && s.withImage,
          gradient && !color && s.gradient,
          centerContent && s.centerContent,
        )}
        style={backgroundStyle}
      >
        {gradient && (
          <div className={s.brand}>
            {logo ? <img src={logo} alt="Logo" /> : <Brand />}
          </div>
        )}
        <div className={cx(focusContentBox && s.focus)}>{children}</div>
        {selectCountryMessage && (
          <div className={s.selectCountryMessage}>
            <span>
              {formatMessage({ ...messages.currentCountry })}{' '}
              <b>
                <Flag country={countryCode} /> {currentCountry}.{' '}
              </b>
            </span>
            <span>
              {formatMessage({ ...messages.notCorrect })}
              <Clickable
                tag="a"
                onClick={e => {
                  e.preventDefault();
                  scrollToBottom();
                }}
                label={<FormattedMessage {...messages.selectCountry} />}
              />
            </span>
          </div>
        )}
        <Notifications />
      </div>
      {footer && <Footer links={{}} />}
      <div className={s.focusBottom}>
        {footerBar && <FooterBar links={FOOTERBAR_LINKS} bigGlobalButton />}
      </div>
    </>
  );
};

FocusLayout.propTypes = {
  intl: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  lightTheme: PropTypes.bool,
  paperTheme: PropTypes.bool,
  noPadding: PropTypes.bool,
  gradient: PropTypes.bool,
  backgroundImage: PropTypes.string,
  footer: PropTypes.bool,
  footerBar: PropTypes.bool,
  logo: PropTypes.string,
  color: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  countryCode: PropTypes.string.isRequired,
  selectCountryMessage: PropTypes.bool,
  focusContentBox: PropTypes.bool,
  centerContent: PropTypes.bool,
};

FocusLayout.defaultProps = {
  lightTheme: false,
  paperTheme: false,
  noPadding: false,
  gradient: false,
  backgroundImage: undefined,
  footer: false,
  footerBar: false,
  logo: undefined,
  color: undefined,
  selectCountryMessage: false,
  focusContentBox: true,
  centerContent: false,
};
const mapState = state => ({
  countries: state.config.countries,
  countryCode: state.intl.countryCode,
});

export default compose(
  withResponsiveWrapper(),
  injectIntl,
  withStyles(normalizeCss, s),
  connect(mapState),
)(FocusLayout);
